import supportApi from '@rosfines/vue-common/common/api/supportApi';
import { TICKET_STATUSES } from '@/constants/support'

let resolveWaitLoadingTickets;
let rejectWaitLoadingTickets;
let ticketsLoad;
function reInitTicketsLoad () {
  resolveWaitLoadingTickets && resolveWaitLoadingTickets();
  ticketsLoad = new Promise((resolve, reject) => {
    resolveWaitLoadingTickets = resolve;
    rejectWaitLoadingTickets = reject;
  });
}
reInitTicketsLoad();

const support = {
  namespaced: true,
  state () {
    return {
      ticketsList: [],
      isLoaded: false,
      contactEmail: '',
      contactPhone: '',
      phones: [],
      emails: [],
      profilePhone: '',
      profileEmail: '',
      restrictions: {
        allowCreateTicket: false,
        allowReopenTicket: false,
      },
    }
  },
  getters: {
    ticketById: (state) => (ticketId) => {
      const ticket = state.ticketsList.filter((ticket) => ticket.id === ticketId);
      return ticket && ticket[0] || {};
    },
    contactsData: (state) => () => {
      return {
        contactEmail: state.contactEmail,
        contactPhone: state.contactPhone,
        profilePhone: state.profilePhone,
        profileEmail: state.profileEmail,
        phones: state.phones,
        emails: state.emails,
      };
    },
    isAllowCreateTicket: ({ restrictions: { allowCreateTicket } }) => allowCreateTicket,
    isAllowReopenTicket: ({ restrictions: { allowReopenTicket } }) => allowReopenTicket,
  },
  mutations: {
    updateTicketByData(state, data) {
      const idx = state.ticketsList.findIndex(({ id }) => {
        return data.id === id;
      });
      if (idx !== -1) {
        state.ticketsList[idx] = data;
      }
    },
    updateRestrictions(state, { allowCreateTicket, allowReopenTicket }) {
      state.restrictions = { allowCreateTicket, allowReopenTicket };
    },
    setTicketsList(state, value) {
      value.sort((a, b) => (
        (a.hasNewNotifications && !b.hasNewNotifications)
          ? -1
          : (!a.hasNewNotifications && b.hasNewNotifications)
            ? 1
            : (a.status === TICKET_STATUSES.WAITING && b.status !== TICKET_STATUSES.WAITING)
              ? -1
              : (a.status !== TICKET_STATUSES.WAITING && b.status === TICKET_STATUSES.WAITING)
                ? 1
                : (a.status === TICKET_STATUSES.OPEN && b.status !== TICKET_STATUSES.OPEN)
                  ? -1
                  : (a.status !== TICKET_STATUSES.OPEN && b.status === TICKET_STATUSES.OPEN)
                    ? 1
                    : (a.updatedTime > b.updatedTime)
                      ? -1
                      : ((a.updatedTime < b.updatedTime)
                        ? 1
                        : 0
                      )
        )
      );
      state.isLoaded = true;
      state.ticketsList = value;
    },
    markAsRead(state, ticketId) {
      const ticket = state.ticketsList.filter((ticket) => ticket.id === ticketId);
      if (ticket && ticket[0]) {
        ticket[0].hasNewNotifications = false;
      }
    },

    setTicketContactData(state, data) {
      state.contactPhone = data.phone ? data.phone : '';
      state.contactEmail = data.email ? data.email : '';
    },

    setTicketInfoFields(state, { ticketId, fields }) {
      const ticket = state.ticketsList.filter((ticket) => ticket.id === ticketId);
      if (ticket && ticket[0]) {
        for (let fieldName in fields) {
          ticket[0][fieldName] = fields[fieldName];
        }
      }
    }
  },
  actions: {
    async waitLoadingTicketsList() {
      return ticketsLoad;
    },
    async loadTicketsList({commit}) {
      const response = await supportApi.get("/tickets").catch(async () => {
        throw new Error('failed loading tickets list');
      });
      if (supportApi.isSuccess(response)) {
        commit("setTicketsList", response.data.items);
        resolveWaitLoadingTickets();
      } else {
        rejectWaitLoadingTickets();
        throw new Error('failed parsing tickets list');
      }
      return response;
    },
    async markTicketAsRead({ dispatch }, ticketId) {
      await supportApi.post(`/ticket/${ticketId}/read-messages`).catch(async () => {
        throw new Error('failed loading tickets list');
      });
      dispatch("app/loadNotificationsCount", undefined, {root: true})
    },
    async loadTicketData({commit}, ticketId) {
      const response = await supportApi.get(`ticket/${ticketId}`).catch(async () => {
        throw new Error('failed loading user contacts');
      });
      if (supportApi.isSuccess(response)) {
        commit("setTicketContactData", response.data);
      }
      return response;
    },
    watchTicket({ commit }, { ticketId, ttl_sec }) {
      const formData = new FormData();
      formData.append('ttl_sec', ttl_sec);
      supportApi.post(`ticket/${ticketId}/watch`, formData)
        .then(({ data }) => commit("updateTicketByData", data))
        .catch(() => { throw new Error('Unable to load ticket data'); });
    },
    async changeTicketStatus({ commit }, { ticketId, status }) {
      const response = await supportApi.post(`/ticket/${ticketId}/${status}`, {})
        .then(({ data }) => commit("updateTicketByData", data))
        .catch(async () => {
          throw new Error('failed loading user contacts');
        });

      return response;
    },
    loadUserRestrictions({ commit }) {
      supportApi.get("user/restrictions")
        .then(({ data: { allowCreateTicket = true, allowReopenTicket = true } }) => commit("updateRestrictions", { allowReopenTicket, allowCreateTicket }))
        .catch(() => { commit("updateRestrictions", { allowCreateTicket: true, allowReopenTicket: true }) });
    },
  }
}

export default support;
