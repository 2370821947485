export default {
  data() {
    return {
      isMobileWidth: true,
    }
  },
  beforeMount() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();
  },
  unmount() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      const width = window.innerWidth;
      // 560 это $lg-width из src/assets/styles/_vars.scss
      if (width < 560) {
        if (!this.isMobileWidth) {
          this.isMobileWidth = true;
        }
      } else {
        if (this.isMobileWidth) {
          this.isMobileWidth = false;
        }
      }
    }
  }
}