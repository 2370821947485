<template>
  <header class="app-header">
    <div class="app-header__title">
      <div
        v-if="enableClose"
      >
        <div
          v-if="enableClose"
          class="app-header__close"
          @click="closeApp"
        >
          <img
            src="@/assets/icons/header/back.svg"
            alt=""
          >
        </div>
      </div>
      <h1>{{ title }}</h1>
    </div>
    <user-balance />
  </header>
</template>

<script>

import { mapActions } from 'vuex';
import UserBalance from '@/components/blocks/misc/UserBalance';

export default {
  name: "AppHeader",
  components: {
    UserBalance
  },
  props: {
    title: {
      type: String,
      default: "Мои сообщения",
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    enableClose() {
      const hasWebToNativeBridge = (window.WebToNativeBridge && window.WebToNativeBridge.postMessage) || (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.WebToNativeBridge);
      return hasWebToNativeBridge && this.showClose;
    }
  },
  methods: {
    ...mapActions({
      sendCloseEvent: "communications/sendCloseEvent",
    }),
    closeApp() {
      this.sendCloseEvent();
    }
  }
}
</script>
