import { createApp } from 'vue';

import "vue-loading-overlay/dist/css/index.css";
import "@/assets/styles/style.scss";

import '@rosfines/vue-common/vue/util/validate';

import App from './App';
import { store } from './store'
import router from './router';

import VueFilters from '@rosfines/vue-common/vue/util/vue.filters';

const VueApp = window.VueApp = createApp(App);
VueApp.use(store);
VueApp.use(router);
VueApp.mount('#app');

import "./utils/tracking";

VueApp.config.globalProperties.$filters = VueFilters;

if (process.env.NODE_ENV === 'production') {
  require('./assets/scripts/metrika');
}
