import finesApi from '@rosfines/vue-common/common/api/finesApi';

let resolveWaitLoadingFeatures;
let rejectWaitLoadingFeatures;
let featuresLoad;
function reInitFeaturesLoad () {
  resolveWaitLoadingFeatures && resolveWaitLoadingFeatures();
  featuresLoad = new Promise((resolve, reject) => {
    resolveWaitLoadingFeatures = resolve;
    rejectWaitLoadingFeatures = reject;
  });
}
reInitFeaturesLoad();

const features = {
  namespaced: true,
  state () {
    return {
      featuresList: []
    }
  },
  getters: {
    isFeatureEnabled: (state) => (featureId) => {
      const feature = state.featuresList.filter((feature) => feature.id === featureId);
      return !!feature.length;
    },
  },
  mutations: {
    setFeaturesList(state, value) {
      state.featuresList = value;
    }
  },
  actions: {
    async waitLoadingFeaturesList() {
      return featuresLoad;
    },
    async loadFeaturesList({state, commit}) {
      if (!state.appVersion) {
        const response = await finesApi.get("/feature").catch(async (e) => {
          console.error(e.toJSON());
          rejectWaitLoadingFeatures();
        });
        if (finesApi.isSuccess(response)) {
          commit("setFeaturesList", response.data.response.features);
          resolveWaitLoadingFeatures();
        } else {
          rejectWaitLoadingFeatures();
        }
        return response;
      }
    },
  }
}

export default features;
