import financeApi from '@rosfines/vue-common/common/api/financeApi'
import envHelper from '@/utils/environment.helper'
import { CommunicationsToolbarDisabled, FileUploadsEnabled } from '@/constants/minVersions';
import { AutoRequestLogs } from '../constants/minVersions';

const versionCompare = function({androidVer, iosVer}, currentVersion) {
  const minVersion = (!envHelper.IS_ANDROID) ? iosVer : androidVer;

  const minAppVersion = String(minVersion).split(".");
  const currentAppVersion = String(currentVersion).split(".");

  const dots = Math.max(minAppVersion.length, currentAppVersion.length);

  const minAppVersionC = minAppVersion.reduce(
    (accumulator, currentValue, index) => accumulator + parseInt(currentValue) * Math.pow(1000, dots-index),
    0
  );
  const currentAppVersionC = currentAppVersion.reduce(
    (accumulator, currentValue, index) => accumulator + parseInt(currentValue) * Math.pow(1000, dots-index),
    0
  );

  return !!(currentAppVersionC && currentAppVersionC >= minAppVersionC);
}

const appVersion = {
  namespaced: true,
  state () {
    return {
      appVersion: null
    }
  },
  getters: {
    communicationsToolbarDisabled(state) {
      return versionCompare(CommunicationsToolbarDisabled, state.appVersion) || !state.appVersion;
    },
    FileUploadsEnabled(state) {
      return versionCompare(FileUploadsEnabled, state.appVersion) || !state.appVersion;
    },
    autoRequestLogsEnabled(state) {
      return versionCompare(AutoRequestLogs, state.appVersion) || !state.appVersion;
    },
  },
  mutations: {
    setAppVersion(state, value) {
      state.appVersion = value;
    }
  },
  actions: {
    async loadAppVersion({state, commit}) {
      if (!state.appVersion) {
        const response = await financeApi.get("/appversion").catch(async (e) => {
          console.error(e.toJSON());
        });
        if (financeApi.isSuccess(response)) {
          commit("setAppVersion", response.data.version);
        }
        return response;
      }
    },
  }
}

 export default appVersion;
