import finesApi from '@rosfines/vue-common/common/api/finesApi';

const app = {
  namespaced: true,
  state () {
    return {
      communications: {},
      channel: "",
    }
  },
  mutations: {
    setCommunications(state, value) {
      state.communications = value;
    },
    setChannel(state, value) {
      state.channel = value;
    },
  },
  actions: {
    async loadNotificationsCount({commit}) {
      const response = await finesApi.get("/notification/stat/count/communications").catch(async (e) => {
        console.error(e.toJSON());
      });
      if (finesApi.isSuccess(response)) {
        commit("setCommunications", response.data.response);
      }
      return response;
    },
    restoreChannel({commit}) {
      commit("setChannel", localStorage.getItem("storedChannel") || "");
    },
    setChannel({commit}, value) {
      localStorage.setItem("storedChannel", value);
      commit("setChannel", value);
    },
  }
}

export default app;
