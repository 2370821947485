import PushNotifications from '@rosfines/core-web/entities/push/PushNotifications';
import pushApi from '@rosfines/core-web/api/push.js'

import finesApi from '@rosfines/vue-common/common/api/finesApi';

const notifications = {
  namespaced: true,
  state () {
    return {
      notifications: []
    }
  },
  mutations: {
    setNotificationsCount(state, value) {
      state.notifications = value;
    }
  },
  actions: {
    async loadNotifications({commit}) {
      const auth = finesApi.getRequestParams();
      const tabsResponse = await pushApi.getPushTabs(auth);
      const pushNotifications = new PushNotifications(auth, tabsResponse)
      const unsortedPushes = await pushNotifications.prepareNotifications();

      const pushes = PushNotifications.getNotifications(0, unsortedPushes, false)
        .filter((push) => push.title !== 'Silent push');
      const isPushes = !!pushes.length

      commit("setNotificationsCount", isPushes ? pushes : []);
    },
  }
}

export default notifications;
