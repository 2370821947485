class EnvironmentHelper {
  constructor() {
    this.IS_IOS = false;
    this.IS_ANDROID = false;
    this.IS_BROWSER = false;
    this.IS_WEB_VIEW = false;
    this.IS_STANDALONE = false; // progressive web app?
    // установит флаги, перечисленные выше в зависимости от окружения
    this.checkEnvironment();
  }
  checkEnvironment() {
    let standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);
    if(ios) {
      this.IS_IOS = true;
      if ( !standalone && safari ) {
        //browser
        this.IS_BROWSER = true;
      } else if ( standalone && !safari ) {
        //standalone
        this.IS_STANDALONE = true;
      } else if ( !standalone && !safari ) {
        //uiwebview
        this.IS_WEB_VIEW = true;
      }
    } else {
      //not iOS
      this.IS_IOS = false;
      this.IS_ANDROID = /android/.test(userAgent);
      this.IS_WEB_VIEW = /wv/.test(userAgent);
    }
  }

}

const envHelper = new EnvironmentHelper();

export default envHelper;
