export const TICKET_STATUSES = {
  OPEN: "open",
  CLOSED: "closed",
  WAITING: "waiting",
};

export const TICKET_RATES = {
  LOW: "low",
  HIGH: "high",
  MIDDLE: "middle"
};
export const TICKET_RATED = {
  LOW: "rating_message_low",
  HIGH: "rating_message_high",
  MIDDLE: "rating_message_middle",
};
