import {statService} from '@rosfines/vue-common/common/services/statService';
import supportApi from '@rosfines/vue-common/common/api/supportApi';

const GET = new URL(location.href).searchParams;
if (GET.get("source")) {
  localStorage.setItem("source", GET.get("source"));
}
const source = localStorage.getItem("source") || "";
const mod = GET.get("mod") || "";

statService.init(supportApi, undefined, {
  source,
  mod,
  eventPrefix: "[Коммуникации] ",
});
