export const NdflDeductionVer = {
  androidVer: '3.69',
  iosVer: '5.94',
};

export const CommunicationsToolbarDisabled = {
  androidVer: '3.71',
  iosVer: '5.97',
};

export const FileUploadsEnabled = {
  androidVer: '3.71',
  iosVer: '0',
};

export const AutoRequestLogs = {
  androidVer: '1000.0',
  iosVer: '5.106',
};
