<template>
  <app-header
    v-if="communicationsToolbarDisabled"
    :show-close="!isNestedRoute"
  />
  <div class="page-wrapper">
    <div class="page-grid">
      <div
        class="page-grid__left"
        :class="{
          'page-grid__left_mobile': isMobileWidth && isNestedRoute,
        }"
      >
        <section-tabs />
      </div>
      <div
        v-if="!isMobileWidth || (isMobileWidth && isNestedRoute)"
        class="page-grid__right"
        :class="{
          'page-grid__right_mobile': isMobileWidth,
          'd-none': toHideRightBlock,
        }"
      >
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import SectionTabs from "@/layouts/blocks/SectionTabs";

import isMobileChecker from '@/mixins/isMobileChecker';
import AppHeader from '@/layouts/blocks/AppHeader';
import { mapState, mapGetters } from 'vuex';

export default {
  name: "MainLayout",
  components: {
    AppHeader,
    SectionTabs,
  },
  mixins: [
    isMobileChecker,
  ],
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      currentPushes: (state) => state.notifications.notifications,
    }),
    ...mapGetters({
      communicationsToolbarDisabled: "appVersion/communicationsToolbarDisabled",
    }),
    route() {
      return this.$route.path;
    },
    isNestedRoute() {
      return this.route.split("/").filter((item) => !!item).length > 1;
    },
    toHideRightBlock(){
      return this.$route.name === "notifications" && this.currentPushes.length !== 0;
    },
  },
  watch: {
    communicationsToolbarDisabled() {
      this.onCommunicationsToolbarDisabled();
    },
  },
  mounted() {
    this.onCommunicationsToolbarDisabled();
  },
  methods: {
    onCommunicationsToolbarDisabled() {
      if (this.communicationsToolbarDisabled) {
        document.body.classList.remove("no-app-header");
      } else {
        document.body.classList.add("no-app-header");
      }
    }
  }
}
</script>
